/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

input {
  height: 1rem;
}
table[_ngcontent-ng-c3799888204] tbody[_ngcontent-ng-c3799888204] tr[_ngcontent-ng-c3799888204] td[_ngcontent-ng-c3799888204] {
 // border: 0px solid black !important;
  text-align: end;
}
.table th, .table td {
  padding: 4px !important;
  vertical-align: top;
//  border-top: 1px solid #e9ecef;
}
.combobox {
  position: relative;
  width: 28%;
  input {
    width: 100%;
    height: 45px;
    border-width: 0 0 1px;
    background-color: rgb(0, 0, 0, 0.04);
    outline: none;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }

  ul {
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 50px;
    min-height: auto;
    max-height: 150px;
    margin: 0;
    border-radius: 05px;
    z-index: 10;
    padding: 0;
    box-shadow: 2px 2px 5px #cdcbcb52;
    overflow: auto;

    li {
      list-style: none;
      padding: 10px 2rem;
      cursor: pointer;

      &:hover {
        background: #cdcbcb52;
      }
    }
  }
}
.new-button-css{
  width: 15% !important;
  background-color: #3f51b5 !important;
  height: 39px !important;
  border: none !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.ftwt-500{
  font-weight: 500;
}
